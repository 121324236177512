var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-4" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center flex-column align-center" },
        [
          _c("span", { staticClass: "font-weight-bold text-title" }, [
            _vm._v(_vm._s(_vm.$t(`game.${_vm.data.type}`))),
          ]),
          _c(
            "svg",
            {
              staticClass: "mt-1",
              attrs: {
                width: "68",
                height: "4",
                viewBox: "0 0 68 4",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("rect", {
                attrs: {
                  width: "68",
                  height: "4",
                  rx: "2",
                  fill: "url(#87qqsuvi7a)",
                },
              }),
              _c(
                "defs",
                [
                  _c(
                    "linearGradient",
                    {
                      attrs: {
                        id: "87qqsuvi7a",
                        x1: "-3.803",
                        y1: "0",
                        x2: "30.196",
                        y2: "37.907",
                        gradientUnits: "userSpaceOnUse",
                      },
                    },
                    [
                      _c("stop", { attrs: { "stop-color": "#E6CE71" } }),
                      _c("stop", {
                        attrs: { offset: ".237", "stop-color": "#BC954D" },
                      }),
                      _c("stop", {
                        attrs: { offset: ".513", "stop-color": "#A57539" },
                      }),
                      _c("stop", {
                        attrs: { offset: ".779", "stop-color": "#C09A50" },
                      }),
                      _c("stop", {
                        attrs: { offset: "1", "stop-color": "#E6CE71" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.data.data
        ? _c(
            "swiper",
            {
              ref: "gamesSlider",
              staticClass: "mySwiper py-0 mt-5",
              attrs: { options: _vm.swiperOption },
            },
            _vm._l(_vm.data.data.games, function (game, index) {
              return _c("swiper-slide", { key: index }, [
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.goGame(index, game.game_code)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "rounded" }, [
                      _c(
                        "div",
                        { staticClass: "games-item rounded pa-1" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.baseImgUrl + game.game.image,
                              cover: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ])
            }),
            1
          )
        : _c("div", { staticClass: "text-center mt-5 caption" }, [
            _vm._v(" empty "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }